<template>
  <div style="height: 100vh;width:100vw;margin:-12px;">
    <div class="content">
      <div class="background"></div>
      <v-card :class="'login' + cardClasses" flat>

        <v-card-text>
          <div>
              <v-img src="@/assets/logo.png" width="125"></v-img>
          </div>

          <nav class="radio-toggles" style="margin:20px 0;" v-show="view != 'confirm' && view != 'forgot' && view != 'change'">
            <input v-model="view" type="radio" id="option-1" name="radio-options" value="login">
            <label for="option-1">Iniciar sesión</label>
            <input v-model="view" type="radio" id="option-2" name="radio-options" value="register">
            <label for="option-2">Registro</label>
            <div class="radio-toggles__slider"></div>
          </nav>

          <!-- login view -->
          <div v-show="view == 'login'">
            <div style="margin: 20px 0 0 0;">
              <div text dense class="text-center error--text" v-show="error.length > 0">{{ error }}</div>
              <v-form v-model="valid" ref="loginForm">
                <v-text-field v-model="username" placeholder="Email" dense :rules="emailRules" required></v-text-field>
                <v-text-field v-model="password" placeholder="Contraseña" :type="viewPassword ? 'text' : 'password'" 
                  :append-icon="viewPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" dense 
                  @click:append="viewPassword = !viewPassword" :rules="passwordRules" required></v-text-field>

                <template v-if="requireNewPassword">
                  <span class="caption">Su password ha expirado. Por favor, entre uno nuevo.</span>
                  <v-text-field v-model="newPassword" :type="showNewPassword ? 'text' : 'password'" placeholder="New Password" 
                    :hide-details="newPasswordError.length == 0" required :rules="passwordRules" 
                    :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'" @click:append="showNewPassword = !showNewPassword"></v-text-field>
                </template>
              </v-form>
            </div>

            <a href="#" class="forgotPassword" @click.prevent="view = 'forgot'">¿Olvidaste tu contraseña?</a>

            <v-btn depressed rounded block dark color="primary" @click="login" :loading="loading" style="text-transform:none;margin: 30px 0;">Iniciar sesión</v-btn>

            <v-btn depressed rounded block color="grey lighten-4" style="text-transform:none;margin: 10px 0 0 0;" @click="googleSignup">
              <v-row no-gutters>
                <v-col cols="3">
                  <img src="../assets/icons/google.svg" width="20" height="20" />
                </v-col>
                <v-col cols="9" class="grey--text text--darken-2 caption text-left">
                  Sign Up with Google
                </v-col>
              </v-row>
            </v-btn>
            <!-- <v-btn depressed rounded block color="grey lighten-4" style="text-transform:none;margin: 10px 0 0 0;" @click="facebookSignup">
              <v-row no-gutters>
                <v-col cols="3">
                  <img src="../assets/icons/facebook.svg" width="20" height="20" />
                </v-col>
                <v-col cols="9" class="grey--text text--darken-2 caption text-left">
                  Sign Up with Facebook
                </v-col>
              </v-row>
            </v-btn> -->

            <div class="caption text-center" style="margin-top: 20px;font-size:13px;">Síguenos</div>
            <div class="text-center">
              <v-icon color="primary" size="50" style="margin-top: 20px;" @click="toInstagram">mdi-instagram</v-icon>
            </div>
          </div>

          <!-- registration view -->
          <div v-show="view == 'register'">
            <div style="margin: 20px 0 0 0;">
              <v-form v-model="signupValid" ref="signupForm">
                <v-row no-gutters>
                  <v-col>
                    <v-text-field v-model="firstName" placeholder="Nombre" dense :rules="firstNameRules"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="lastName" placeholder="Apellido" dense :rules="lastNameRules"></v-text-field>
                  </v-col>
                </v-row>

                <v-text-field v-model="email" placeholder="Email" dense :rules="emailRules" :error-messages="emailError"></v-text-field>
                <v-text-field v-model="phone" placeholder="Teléfono" v-mask="'(###) ###-####'" dense :rules="phoneRules"></v-text-field>
                <v-text-field v-model="password" placeholder="Contraseña" :type="viewPassword ? 'text' : 'password'" :rules="passwordRules" :error-messages="passwordError"
                  :append-icon="viewPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" dense @click:append="viewPassword = !viewPassword"></v-text-field>
                <v-text-field v-model="confirmPassword" placeholder="Confirmación de contraseña" :type="viewConfirmPwd ? 'text' : 'password'" :rules="confirmPasswordRules"
                  :append-icon="viewConfirmPwd ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" dense @click:append="viewConfirmPwd = !viewConfirmPwd"></v-text-field>
              </v-form>
            </div>

            <v-btn depressed rounded block dark color="primary" style="text-transform:none;margin: 30px 0 0 0;" @click="appSignup" :loading="loading">Registrarme</v-btn>
          </div>

          <!-- confirmation view -->
          <div v-show="view == 'confirm'">
            <div style="margin: 20px 0 0 0;">
              <div class="my-4">Se ha enviado un código a su correco electrónico. Por favor, entre el código en campo de abajo para confirmar su correo electrónico.</div>

              <div class="py-5"></div>

              <v-text-field v-model="code" placeholder="Código de confirmación" dense :rules="[v => !!v || 'el código es requerido']" 
                  :error-messages="codeError"></v-text-field>

              <div class="py-5"></div>

              <v-btn depressed rounded block dark color="primary" style="text-transform:none;margin: 30px 0 0 0;" @click="confirmEmail" :loading="loading">Confirmar</v-btn>
            </div>
          </div>

          <!-- forgot password view -->
          <div v-show="view == 'forgot'">
            <div style="margin: 20px 0 0 0;">
              <div class="my-4">Entre el correo electrónico de su cuenta.</div>
              <v-form ref="recoverForm">
                <div class="py-5"></div>

                <v-text-field v-model="username" placeholder="Correo electrónico" :rules="emailRules" :error-messages="usernameError"></v-text-field>

                <div class="py-5"></div>
              </v-form>

              <v-btn depressed rounded block dark color="primary" style="text-transform:none;margin: 30px 0 0 0;" @click="forgotPasswordClick" :loading="loading">Recuperar</v-btn>
              <div class="mt-5 text-center">
                <a href="#" style="color:blue;text-decoration:none;" @click.prevent="view = 'login'">&#60;-- Volver atrás</a>
              </div>
            </div>
          </div>

          <!-- change password view -->
          <div v-show="view == 'change'">
            <div style="margin: 20px 0 0 0;">
              <div class="my-4">Se ha enviado un código a su correco electrónico. Por favor, entre el código en campo de abajo y la nueva contraseña para su cuenta.</div>
              <v-form ref="changeForm">
                <v-text-field v-model="code" placeholder="Código de confirmación" dense :rules="[v => !!v || 'el código es requerido']" 
                  :error-messages="codeError"></v-text-field>
                <v-text-field v-model="password" placeholder="Contraseña" :type="viewPassword ? 'text' : 'password'" :rules="passwordRules" :error-messages="passwordError"
                  :append-icon="viewPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" dense @click:append="viewPassword = !viewPassword"></v-text-field>
                <v-text-field v-model="confirmPassword" placeholder="Confirmación de contraseña" :type="viewConfirmPwd ? 'text' : 'password'" :rules="confirmPasswordRules" :error-messages="confirmPasswordError"
                  :append-icon="viewConfirmPwd ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" dense @click:append="viewConfirmPwd = !viewConfirmPwd"></v-text-field>
              </v-form>

              <v-btn depressed rounded block dark color="primary" style="text-transform:none;margin: 30px 0 0 0;" @click="changePasswordClick" :loading="loading">Cambiar contraseña</v-btn>
              <div class="mt-5 text-center">
                <a href="#" style="color:blue;text-decoration:none;" @click.prevent="view = 'login'">&#60;-- Volver atrás</a>
              </div>
            </div>
          </div>
        </v-card-text>

      </v-card>

    </div>
    <div class="copyrights">© Petu Power {{ year }}. All rights reserved.</div>
  </div>
</template>

<script type="scss">
import { mapGetters, mapActions } from 'vuex';
import { _st } from '../softech';
import { mask } from 'vue-the-mask';
export default {
  data() {
    return {
      valid             : true,
      signupValid       : true,

      view              : 'login',
      username          : '',
      password          : '',
      email             : '',
      confirmPassword   : '',
      firstName         : '',
      lastName          : '',
      phone             : '',
      code              : '',

      usernameError     : '',
      usernameRules     : [
        v => !!v || 'el email es requerido',
      ],
      passwordError     : '',
      passwordRules     : [
        v => !!v || 'el password es requerido',
      ],
      newPassword       : '',
      newPasswordError  : '',
      firstNameRules    : [
        v => !!v || 'el nombre es requerido',
      ],
      lastNameRules     : [
        v => !!v || 'el apellido es requerido',
      ],
      emailError        : '',
      emailRules        : [
        v => !!v || 'el email es requerido',
        v => !!_st.isEmail(v) || 'el email tiene que ser válido'
      ],
      phoneRules        : [
        v => !!v || 'el teléfono es requerido',
        v => !!_st.isPhone(v) || 'el teléfono tiene que ser válido'
      ],
      confirmPasswordError: '',
      confirmPasswordRules: [
        v => !!v || 'el password es requerido',
        v => !!(v == this.password) || 'no es igual que el password'
      ],
      codeError         : '',

      error             : '',

      viewPassword      : false,
      viewConfirmPwd    : false,
      loading           : false,
      requireNewPassword: false,
    }
  },
  computed: {
    ...mapGetters({
      user            : 'auth/user'
    }),
    year() {
      return (new Date()).getFullYear();
    },
    cardClasses() {
      let classes = '';
      if(this.view == 'register')
        classes += ' register';
      if(this.view == 'forgot')
        classes += ' forgot';

      return classes;
    }
  },
  methods: {
    ...mapActions({
      loginVue                : 'auth/login',
      completeNewPasswordVue  : 'auth/completeNewPassword',
      confirmSignUp           : 'auth/confirmSignUp',
      signup                  : 'auth/signUp',
      forgotPassword          : 'auth/forgotPassword',
      submitForgotPassword    : 'auth/submitForgotPassword',
      federatedSignIn         : 'auth/federatedSignIn',
    }),
    async login() {
      this.error = '';
      if(!this.$refs.loginForm.validate()) {
        this.error = 'El email y el password son requeridos.';
        return;
      }

      try {
        this.loading = true;
        if(this.requireNewPassword) {
          // change password while loggin in for the first time
          await this.completeNewPasswordVue({
              username: this.username,
              oldPassword: this.password,
              newPassword: this.newPassword
          });
        }
        else {
          // login regularly')
          await this.loginVue({ username: this.username.trim(), password: this.password });
        }

        if(!_st.isNU(this.user) && this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.requireNewPassword = true;
          this.loading = false;
          return;
        }
        // else if(!_st.isNU(this.user) && this.)

        let returnUrl = localStorage.getItem('returnUrl');
        if (!_st.isNUE(returnUrl)) {
          this.$router.push(returnUrl)
          localStorage.removeItem('returnUrl')
        } else {
          this.$router.push('/')
        }

        this.loading = false;
      } 
      catch (error) {
        this.loading = false;

        if(error && error?.code == 'UserNotConfirmedException') { // go to confirmation view
          this.view = 'confirm';
        }

        this.error = error.message;
      }
    },
    async appSignup() {
      try {
        this.error = '';
        this.passwordError = ''; 
        this.emailError = '';
        if(!this.$refs.signupForm.validate()) {
            this.error = 'Todos los campos son requeridos.';
            return;
        }
        if(this.password != this.confirmPassword) {
          this.error = 'La contraseña y su confirmación no son iguales.';
          return;
        }

        this.loading = true;
        await this.signup({
          username: this.email.trim(),
          password: this.password,
          email: this.email.trim(),
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone
        });

        this.loading = false;
        this.username = this.email.trim();
        this.view = 'confirm';
      } 
      catch(error) {
        this.loading = false;
        this.error = error.message;

        if(error.code == 'InvalidPasswordException')
          this.passwordError = error.message;
        else if(error.code == 'UsernameExistsException')
          this.emailError = 'Ya existe una cuenta con este email';
      }
    },
    async googleSignup() {
      try {
        await this.federatedSignIn('Google');
      } catch(error) {
        console.log(error);
        this.loading = false;
      }
    },
    facebookSignup() {

    },
    async confirmEmail() {
      try {
        this.loading = true
        await this.confirmSignUp({ username: this.email, code: this.code })
        this.view = 'login'
        await this.login()
      } catch(error) {
        console.log(error)
        this.codeError = error?.message
      } finally {
        this.loading = false
      }
    },
	async forgotPasswordClick() {
		try {
      this.usernameError = '';
      if(!this.$refs.recoverForm.validate()) 
        return;

      this.loading = true;
      await this.forgotPassword(this.username.trim());

      this.password = '';
      this.loading = false;
      this.view = 'change';
		}
		catch(error) {
      console.log(error);
      this.loading = false;

      if(error.code == 'UserNotFoundException')
        this.usernameError = 'No existe ninguna cuenta con este email';
		}
	},
	async changePasswordClick() {
		this.usernameError = '';
		this.error = '';
		this.passwordError = ''; 
		this.confirmPasswordError = '';
		this.codeError = '';

		if(!this.$refs.changeForm.validate()) 
		  return;
		if(this.password != this.confirmPassword) {
		this.confirmPasswordError = 'no es igual a la contraseña';
		return;
		}

		this.loading = true;
		try {
		await this.submitForgotPassword({ 
			email: this.username.trim(), 
			code: this.code, 
			newPassword: this.password
		});

		this.loading = false;
		this.view = 'login';
		await this.login();
		}
		catch(error) {
		console.log(error);
		this.loading = false;

		if(error.code == 'CodeMismatchException')
			this.codeError = 'Código inválido';
		}
	},
	toInstagram() {
		window.open('http://www.instagram.com/petunia_anacleta', '_blank');
	}
  },
  directives: { mask }
}
</script>

<style lang="scss" scoped>
/* color1: #ee3a8c */
/* color2: #f68926 */
.content {
  height: 100vh;
  padding: 20px;
  margin: -12px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.background {
  height: 70vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(238,58,140);
  background: linear-gradient(187deg, rgba(238,58,140,1) 0%, rgba(246,137,38,1) 100%);
}
.login {
  border-radius: 25px !important;
  // min-height: 70vh;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
}
// .login.register {
//   height: 80vh;
// }
// .login.forgot {
//   height: 60vh;
// }
.login .v-card__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 30px; 
}

.forgotPassword {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  text-align: right;
  text-decoration: none;
  display: inline-block;
  width: 100%;
}

.copyrights {
    color: green;
    text-align: center;
    position: sticky;
    bottom: 0;
    width: 100%;
    // margin-left: -25px;
    font-size: 12px;
    text-align: center;
}

// toggle buttons
.radio-toggles {
  $size: var(--radio-toggles--size, 50%);
  $font-size-sm: 12px;
  $border-color: #eee;
  $primary: #ee3a8c;
  $white: #fff;
  position: relative;
  display: table;
  font-size: $font-size-sm;
  color: $primary;
  border: 1px solid $border-color;
  border-radius: 2em;

  label {
    position: relative;
    z-index: 1;
    display: table-cell;
    width: $size;
    padding: 1em 2em .95em;
    line-height: 1;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    transition: color .2s ease-out;
  }

  &__slider {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: var(--radio-toggles__slide--left, -9999px);
    // z-index: -1;
    box-sizing: border-box;
    width: $size;
    padding: 4px;
    background-color: $primary !important;
    background-clip: content-box;
    border-radius: 2em;
    transition: left .3s ease-out;
  }

  input[type='radio'] {
    position: absolute;
    left: -9999px;
    z-index: -1;

    &:checked + label {
      color: $white;
    }

    @for $i from 1 through 5 {
      &:nth-of-type(#{$i}):checked ~ .radio-toggles__slider {
        --radio-toggles__slide--left:
          calc(
            #{$size} * (#{$i} - 1)
    );
      }
    }
  }
}

</style>
